import './lagoon.scss';
import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import { getPage, TypeLagoonPageData, TypeLagoonMicroService, TypeLagoonTechnology, TypeLagoonToolCategory, TypeLagoonPageQueryResult } from "../../utils/queries/lagoon";
import { PageTitle } from '../../components/title/title';
import { SectionDivider } from '../../components/section-divider/section-divider';
import { PrismicImage, PrismicRichText } from '../../utils/queries/types';
import Carousel from 'react-multi-carousel';
import Helmet from 'react-helmet';
import AmazeeIoMetadata from '../../components/metadata';
import linkResolver from '../../utils/linkResolver';
import AmazeePage from '../../components/layout';
import { AmazeeIOButton } from '../../components/button/button';
import Linked from '../../utils/linkedItem';
import RichTextRender from '../../utils/rich-text';
import usePreviewData from '../../utils/usePreviewData';


const LagoonDeveloper = ({ image, title, details }: { image: PrismicImage, title: string, details: PrismicRichText }) => {
  return (
    <div className="service-inner">
      <div className="main-image" style={{ backgroundImage: `url('${image.url}')` }} />
      <div className="inner-wrapper">
        <div className="inner-content">
          <div className="top">
            <div className="title">
              {title}
            </div>
          </div>
          <div className="bottom">
            {RichTextRender(details)}
          </div>
        </div>
      </div>
    </div>
  )
};

const LagoonMicroservice = ({ microservice }: { microservice: TypeLagoonMicroService }) => {
  return (
    <div className="col-12 col-md-4 microservice d-flex flex-column align-items-center">
      <div className="top">
        <img src={microservice.icon.url} alt={microservice.icon.alt} />
      </div>
      <div className="bottom text-center">
        <div className="title">{microservice.title}</div>
        <div className="details">{RichTextRender(microservice.details)}</div>
      </div>
    </div>
  );
}

const LagoonTechnology = ({ technology }: { technology: TypeLagoonTechnology }) => {
  return (
    <div className="col-12 col-md-6 col-lg-3 technology d-flex flex-column align-items-center">
      <div className="top">
        <div className='img' style={{ backgroundImage: `url('${technology.logo.url}')` }} />
      </div>
      <div className="bottom text-center">
        <div className="details">{RichTextRender(technology.description)}</div>
      </div>
    </div>
  );
}

const LagoonFeature = ({ image, title, details }: { image: PrismicImage, title: PrismicRichText | string, details: PrismicRichText }) => {
  const titleRendered = (title.hasOwnProperty('raw')) ? RichTextRender(title) : title;
  return (
    <div className="col-12 col-lg-4 lagoon-page-feature">
      <div className="icon">
        <img src={image.url} alt={image.alt} />
      </div>
      <div className="title">{titleRendered}</div>
      <div className="description text-center">{RichTextRender(details)}</div>
    </div>
  )
};

const LagoonCategory = ({ category }: { category: TypeLagoonToolCategory }) => {
  return (
    <div className="category d-flex flex-column align-items-center">
      <div className="category-title">{category.primary.category_name}</div>
      <SectionDivider />
      <div className="row">
        {category.items.map((item, index) => (
          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center" key={index}>
            <div className="img" style={{ backgroundImage: `url('${item.image.url}')` }} />
          </div>
        ))}
      </div>
    </div>
  );
};


const LagoonPage = ({ data, location }: { location: Location, data: TypeLagoonPageQueryResult }) => {
  const edge = data.allPrismicLagoonPage.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(edge.node.data, 'prismicLagoonPage', true);

  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 30000, min: 992 },
      items: 6,
      slidesToSlide: 1
    },
    bigTablet: {
      breakpoint: { max: 992, min: 768 },
      items: 4,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 768, min: 384 },
      items: 3,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 384, min: 0 },
      items: 2,
      slidesToSlide: 1
    }
  };

  return (
    <AmazeePage location={location}>
      <div id='lagoon-page'>
        <PageTitle title={result.page_title} />
        <Helmet>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/solid.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <AmazeeIoMetadata title={result.page_title} page_meta_description={result.page_meta_description} page_meta_thumbnail={result.page_meta_thumbnail} location={location} />

        <section className="hero tmp flex-col d-flex justify-center">

          <div className="container">
            <div className="row justify-end w-full">
            <div className="col-12 col-md-8 col-lg-7 text-left">
              <h4>Discover the benefits of</h4>
              <h2>Lagoon + Kubernetes</h2>
              <AmazeeIOButton classes='d-inline-block inverse inverted'><Linked link_to={{document: {data: {is_external: false, url: '/blast-off-with-lagoon-and-kubernetes'}}}}>Explore Now</Linked></AmazeeIOButton>
            </div>
            </div>
          </div>

        </section>

        <section className="developers dark">
          <div className="container">
            <div className="row services">
              <div className="col-12 col-lg-6 service">
                <LagoonDeveloper image={result.for_developers_image} title={result.for_developers_text} details={result.for_developers_details} />
              </div>
              <div className="col-12 col-lg-6 service padding-top-1">
                <LagoonDeveloper image={result.by_developers_image} title={result.by_developers_text} details={result.by_developers_details} />
              </div>
            </div>
          </div>
        </section>

        <section className="microservices white">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center d-flex flex-column align-items-center">
                <div className="title">{RichTextRender(result.section_header_microservices)}</div>
                <div className="main-title">{result.section_headline}</div>
                <SectionDivider />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="row">
                  {result.microservices.map((microservice, index) => <LagoonMicroservice microservice={microservice} key={index} />)}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="technology dark">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center d-flex flex-column align-items-center">
                <div className="title">
                  {result.headline}
                </div>
                <SectionDivider />
                <div className="subtitle">{RichTextRender(result.subheadline)}</div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-10">
                <div className="row">
                  {result.technologies.map((technology, index) => <LagoonTechnology technology={technology} key={index} />)}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="open-source white">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center d-flex flex-column align-items-center">
                <div className="title">
                  {RichTextRender(result.section_header)}
                </div>
                <SectionDivider />
              </div>
            </div>
          </div>

          <Carousel
            ssr={true}
            responsive={carouselResponsive}
            showDots={false}
            arrows={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            infinite>
            {result.frameworks.map((framework, index) => (
              <div className="logo" key={index}>
                <div className="img" style={{ backgroundImage: `url('${framework.logo.url}')` }} />
              </div>
            ))}
          </Carousel>
        </section>

        <section id="features" className='features dark'>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1">
                <div className="row align-items-start">
                  {result.bullets.map((feature, index) => <LagoonFeature image={feature.image} title={feature.title} details={feature.details} key={index} />)}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tools white">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center d-flex flex-column align-items-center">
                <div className="title">
                  {result.header}
                </div>
              </div>
            </div>
            {result.body.map((category, index) => (
              <div className="row d-flex justify-content-center" key={index}>
                <div className="col-12 col-md-10 col-lg-8">
                  <LagoonCategory category={category}/>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </AmazeePage>
  )
}

const StaticLagoonPage: React.FC<{ location: Location }> = ({ location }) => {
  const query = graphql`
  {
    allPrismicLagoonPage {
      edges {
        node {
          data {
            body {
              ... on PrismicLagoonPageBodyToolingCategory {
                id
                slice_type
                slice_label
                primary {
                  category_name
                }
                items {
                  image {
                    alt
                    url
                  }
                }
              }
            }
            bullets {
              details {
                html
                text
                raw
              }
              image {
                alt
                copyright
                url
                thumbnails
              }
              title
            }
            page_meta_thumbnail {
              alt
              copyright
              url
              thumbnails
            }
            page_meta_description
            by_developers_details {
              html
              text
              raw
            }
            by_developers_image {
              alt
              copyright
              url
              thumbnails
            }
            by_developers_text
            for_developers_details {
              html
              text
              raw
            }
            for_developers_image {
              alt
              copyright
              url
              thumbnails
            }
            for_developers_text
            frameworks {
              logo {
                alt
                copyright
                url
                thumbnails
              }
            }
            header
            headline
            lagoon_image {
              alt
              copyright
              url
              thumbnails
            }
            microservices {
              details {
                html
                text
                raw
              }
              icon {
                alt
                copyright
                url
                thumbnails
              }
              title
            }
            page_subtitle {
              html
              text
              raw
            }
            page_title
            section_header {
              html
              text
              raw
            }
            section_header_microservices {
              html
              text
              raw
            }
            section_headline
            subheadline {
              html
              text
              raw
            }
            reading {
              cta_button_text
              details {
                html
                text
                raw
              }
              image {
                alt
                copyright
                url
                thumbnails
              }
              title {
                html
                text
                raw
              }
            }
            fact_sheet {
              link_type
              url
              size
            }
            technologies {
              logo {
                alt
                copyright
                url
                thumbnails
              }
              description {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  }
  
  `;

  return (
    <StaticQuery
      render={(result => <LagoonPage location={location} data={result} />)}
      query={query} />
  )
}
export default StaticLagoonPage;
